import { LoaderProvider } from "../contexts/LoaderContext";
import { ErrorProvider } from "../contexts/ErrorContext";
import { RegisterProvider } from "../contexts/RegisterContext";
import { Fragment } from "react";
import Head from "next/head";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Page from "../components/Page";
// azure
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../services/azure/authConfig";
import { CustomNavigationClient } from "../services/azure/NavigationClient";

// styles
import "bootstrap/scss/bootstrap.scss";
import "../styles/globals.scss";
import "../styles/mixins.scss";
import "../styles/variables.scss";
import "../styles/register.scss";

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const navigationClient = new CustomNavigationClient(router);
  msalInstance.setNavigationClient(navigationClient);

  const getLayout = Component.getLayout || ((page) => page);

  const googleTagManagerCode = process.env.NEXT_PUBLIC_AZURE_URL_AD_DOMAIN === 'q22appprod'
      ? 'GTM-5HFL7WK8'
      : 'GTM-PLP649D2'

  return (
    <Fragment>
      <MsalProvider instance={msalInstance}>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');
          `}
        </Script>
        <Script>
          {`
            if (document.domain != "media.qatar2022.qa" && document.domain != "www.media.qatar2022.qa") {
            var l = location.href;
            var r = document.referrer;
            var m = new Image();
            if (location.protocol == 'https:') {
                m.src = "https://aff9e95d6dcc.o3n.io/content/dmi5xvjjciea8fbawbvm5uj11/logo.gif?l=" + encodeURI(l) + "&r=" + encodeURI(r);
            } else {
                m.src = "http://aff9e95d6dcc.o3n.io/content/dmi5xvjjciea8fbawbvm5uj11/logo.gif?l=" + encodeURI(l) + "&r=" + encodeURI(r);
            }}
          `}
        </Script>

        {/* Google Tag Manager */}

        <Script id="google-tag-manager">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleTagManagerCode}');
          `}
        </Script>
        {/* End Google Tag Manager */}

        {/*<Script id="hotjar" strategy="afterInteractive">*/}
        {/*  {`*/}
        {/*    (function(h,o,t,j,a,r){*/}
        {/*    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};*/}
        {/*    h._hjSettings={hjid:3093528,hjsv:6};*/}
        {/*    a=o.getElementsByTagName('head')[0];*/}
        {/*    r=o.createElement('script');r.async=1;*/}
        {/*    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;*/}
        {/*    a.appendChild(r);*/}
        {/*    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');*/}
        {/*  `}*/}
        {/*</Script>*/}
        <Page>
          {getLayout(
            <Fragment>
              <Head>
                <title>Hayya Media Portal</title>
                <meta name="description" content="Hayya Media Portal" />
                <meta property="og:image" content="https://stgcms.hayya.qa/wp-content/uploads/2024/11/Logo.svg" />
                <meta
                  name="application-name"
                  content="FIFA World Cup Qatar 2022™"
                />
                <meta name="title" content=" Hayya Media Portal " />
                <meta
                  name="keywords"
                  content="FIFA, World Cup, Qatar 2022™, Hayya Media Portal, Media Centre, FIFA accreditation "
                />
                <meta
                  name="description"
                  content="The Hayya Media Portal will host and deliver exclusive content to help media report on the FIFA World Cup 2022™ and tell Qatar’s story. In addition, the Hayya Media Portal will help to facilitate a range of services, including interview requests, studio bookings in the Host Country Media Centre and media tours."
                />
                <meta name="subject" content="FIFA World Cup Qatar 2022™ " />
                <meta
                  name="copyright"
                  content="Copyrights © Supreme Committee for Delivery & Legacy 2022. All Rights Reserved"
                />
                <meta name="language" content="EN" />
                <link href="https://api.fontshare.com/v2/css?f[]=satoshi@300,400&display=swap" rel="stylesheet" />

                <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cairo:wght@200..1000&family=Noto+Sans+Arabic:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Vazirmatn:wght@100..900&display=swap" rel="stylesheet" />
                <link
                  rel="shortcut icon"
                  type="image/x-icon"
                  href="\assets\images\hayya-fav.png"
                />
                {/* href="/favicon-32x32.webp" */}

              </Head>
              <LoaderProvider>
                <ErrorProvider>
                  <RegisterProvider>
                    {/* <Component {...pageProps} /> */}

                    <Component {...pageProps} />
                  </RegisterProvider>
                </ErrorProvider>
              </LoaderProvider>
            </Fragment>
          )}
        </Page>
      </MsalProvider>
    </Fragment>
  );
}

export default appWithTranslation(MyApp);
