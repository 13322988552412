import styles from "./footer.module.scss";
import Link from "next/link";
import Partners from "../Partners";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import SocialMedia from "../SocialMedia";
export default function Footer() {
  const { t } = useTranslation("common");
  const [language, setLanguage] = useState("english");
  const switchLanguage = (e) => {
    setLanguage(e.target.id);
  };
  return (
    <>
      <Partners />

      <section
        className={`${styles.footer} footer position-relative bg-burgundy-x bg-light-black pt-5`}
      >
        <div className="container">
          <div className="row text-start-x text-light-grey">
            <div className="col-md-4 mb-md-0 mb-4">
              <ul className="line-transition">
                <li>
                  <p>{t("legalTerms")}</p>
                </li>
                {/* <li>
                  <a href="https://qatar2022.qa" target="_blank">
                    Qatar2022.qa
                  </a>
                </li>
                <li>
                  <a href="https://fifa.com" target="_blank">
                    FIFA.com
                  </a>
                </li> */}
                {/* <li>
                  <a href="/assets/pdf/Qatar_Map.pdf" target="_blank">
                    {t("map-of-qatar")}
                  </a>
                </li> */}
                <li className="pb-3 footer-link line-btn">
                  <Link href={"/terms"}>
                    <a>{t("termsofUse")}</a>
                  </Link>
                </li>
                <li className="pb-3 footer-link line-btn">
                  <Link href={"/privacy-policy"}>
                    <a>{t("privacyPolicy")}</a>
                  </Link>
                </li>
                <li className="pb-3 footer-link line-btn">
                  <Link href={"/cookie-policy"}>
                    <a>{t("cookiesPolicy")}</a>
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="col-sm-4 my-sm-0 my-4 pb-sm-0 pb-4">
              <div className="mb-4">
               
              </div> */}
              {/* <SocialMedia
                Color="#FEFEFD"
                textColor="text-light"
                display="flex-column"
                colMediaNumber=""
                colNumber="col-12 justify-content-between order-3"
                font="ff-graphik"
              /> */}
            {/* </div> */}
            <div className="col-md-4 mb-md-0 mb-4">
              <ul className="line-transition">
                <li className="pb-0">
                  <p>{t("support")}</p>
                </li>
                {/* <li className="pb-1">
                  <a href={t("manualpdf")} target="_blank">
                    {t("manualuser")}
                  </a>
                </li> */}
                <li className="pb-3 footer-link line-btn">
                {/* <Link> */}
                  <a target="_blank" href="/faqs">
                    {t("faq")}
                  </a>
                {/* </Link> */}
                </li>
                <li className="pb-3 footer-link line-btn">
                {/* <Link> */}
                  <a target="_blank" href="/contact-us">
                    {t("contact")}
                  </a>
                {/* </Link> */}
                </li>
                <li className="pb-3 footer-link line-btn">
                {/* <Link> */}
                  <a href="https://hayya.qa/en/how-to-guides">
                    {t("howtoguides")}
                  </a>
                {/* </Link> */}
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-between footer-bottom">
            <div>
              {/* <img src="/assets/svg/logo.svg" width="100px"/> */}
             <a href={"/"}>
                <img src="https://stgcms.hayya.qa/wp-content/uploads/2024/11/Logo.svg" width={120} height={100} alt="logo" />
              </a>

              </div>
          <p
            className={`${styles.cpy} text-center text-light-grey pt-md-5-x py-3 mt-3`}
          >
            {`© ${new Date().getFullYear()} ${t("copyrights")}`}
          </p>
        <div className="col-lg-2 col-md-4 col-6">
          <div className="social">
              <ul className="d-flex justify-content-evenly">
                <li>
                    <a href={'https://www.instagram.com/myhayyaqatar/'} target="_blank" rel="noreferrer">
                        <img src={'https://stgcms.hayya.qa/wp-content/uploads/2023/08/Instagram.svg'} alt="instagram" />
                    </a>
                </li>
                <li>
                    <a href={'https://www.youtube.com/@MyHayyaQatar/'} target="_blank" rel="noreferrer">
                        <img src={'https://stgcms.hayya.qa/wp-content/uploads/2023/08/Youtube.svg'} alt="youtube" />
                    </a>
                </li>
                <li>
                    <a href={'https://twitter.com/myhayyaqatar/'} target="_blank" rel="noreferrer">
                        <img src={'https://stgcms.hayya.qa/wp-content/uploads/2023/08/Twitter-1.svg'} alt="twitter" />
                    </a>
                </li>
              </ul>
          </div>
        </div>
      
        </div>
</div>
        {/* <div className={styles.welcome}>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </section>
    </>
  );
}
